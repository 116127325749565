<template>
  <div>
    <welcome-conference-banner class='mt-6 lg:mt-12' background-color='#FFFFFF'/>
    <welcome-message-text class='mt-12 px-2 lg:px-0'/>
    <president-image class='mt-12 px-2 lg:px-0 w-full lg:w-3/5' />
  </div>
</template>

<script>
import WelcomeConferenceBanner from '@/components/welcome/WelcomeConferenceBanner.vue'
import WelcomeMessageText      from '@/components/welcome/WelcomeMessageText.vue'
import PresidentImage          from '@/components/welcome/PresidentImage.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Welcome',
  components: {
    WelcomeConferenceBanner,
    WelcomeMessageText,
    PresidentImage
  },
  props: [
    'needToBeLoggedIn'
  ],
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
  },
  methods: {
    ...mapActions('users', [
      'checkTokenStatus',
    ]),
  },
  created () {
    if (this.needToBeLoggedIn) {
      this.checkTokenStatus().then(() => {
        if (!this.hasValidToken) {
          this.$router.replace({name: 'Login', query: {redirect_route: 'Welcome'} })
        }
      })
    }
  },
}
</script>
